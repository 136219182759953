<template>
  <b-modal
    v-model="myShow"
    ok-title="OK"
    cancel-title="CANCEL"
    centered
    :title="title"
  >

    <div class="d-flex flex-row align-items-center">
      <div class="list-title font-weight-bolder">
        {{ $t('Agent Credit') }} :
      </div>
      <div
        class="list-text w-100 h2"
        style="user-select: text"
      >
        <b-badge
          variant="light-primary"
          class="badge-glow"
        >
          {{ currentCredit | formatNumberMoney }}
        </b-badge>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center py-2">
      <div class="list-title font-weight-bolder">
        {{ IncreaseOrReduceText }}
        <b-button
          :variant="type === 'INCREMENT' ? 'flat-success' : 'flat-danger'"
          class="btn-icon "
          size="sm"
        >
          <feather-icon
            v-if="type === 'INCREMENT'"
            icon="PlusIcon"
          />
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </b-button>
      </div>
      <div class="list-text w-100">
        <b-form
          @submit.prevent="onSave"
        >
          <cleave
            ref="inputTotal"
            v-model="form.total"
            class="form-control"
            :raw="false"
            :options="numberFormat"
          />
        </b-form>
      </div>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <div>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="$emit('update:show', false)"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            size="sm"
            @click.prevent="onSave"
          >
            {{ $t('Confirm') }}
          </b-button>
        </div>
      </div>
    </template>

  </b-modal>
</template>

<script>
import Cleave from 'vue-cleave-component'

export default {
  components: { Cleave },
  props: {
    show: { type: Boolean, required: true },
    type: { type: String, required: true },
    username: { type: String, required: true },
    currentCredit: { type: Number, required: true },
  },
  data() {
    return {
    //   show: true,
      form: { total: '' },
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  computed: {
    IncreaseOrReduceText() {
      return this.type === 'INCREMENT' ? this.$t('Increase Credit') : this.$t('Reduce Credit')
    },
    title() {
      const prefix = this.type === 'INCREMENT' ? this.$t('Increase Credit') : this.$t('Reduce Credit')
      return `${prefix} : ${this.username}`
    },
    myShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      },
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.form.total = ''
      } else {
        setTimeout(() => {
          this.$refs.inputTotal.$el.focus()
        }, 200)
      }
    },
  },
  methods: {
    onSave() {
      this.$emit('onConfirm', String(this.form.total).replace(/,/g, ''))
    },
  },
}
</script>

<style>
.list-main {
    border: 1px solid #e1e1e1;
    padding: 10px 0 10px 0;
    border-radius: 5px;
}
.list-title {
    flex-basis: 40%;
    text-align: right;
    padding-right: 6px;
}
.list-text-border {
    border: 1px solid #e1e1e1;
    padding: 10px 0px 10px 0;
    border-radius: 8px;
    padding-left: 10px;
}

</style>
